div-iframe {
  width: 100%;
  height: 100%;
}

.div-iframe iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (max-width: 600px) {
  div-iframe {
    width: 80vw;
  }
}
