.App {
  color: white;
  margin: 2vw;
}
* {
  box-sizing: border-box;
}

body {
  margin-top: -1vw;
  font-family: "Open Sans", sans-serif;
  background-color: #121420; /* try #171c29 121420 later, debating between these two rn... */
  behavior: smooth;
}

#main-container {
  display: flex;
  flex-direction: row;
}
/* Big picture of me */
#picture-me {
  margin-right: 3vw;
  margin-top: 3vw;
  width: 400px;
  height: 400px;
  border-radius: 3vw;
}

/* Either side of main container */
.side {
  display: flex;
  flex-direction: column;
}
/* used to align text */
.container {
  text-align: center;
}

#quote-container {
  width: 400px;
}

/* Larger Views (Like Monitors and such) */

@media (min-width: 1400px) {
  #picture-me {
    width: 500px;
    height: 500px;
  }
  #quote-container {
    width: 500px;
  }
}
@media (min-width: 2560px) {
  #picture-me {
    width: 750px;
    height: 750px;
  }
  #quote-container {
    width: 750px;
  }
}
@media (max-width: 1000px) {
  #picture-me {
    width: 300px;
    height: 300px;
  }
  #quote-container {
    width: 300px;
  }
}

@media (max-width: 850px) {
  #picture-me {
    width: 275px;
    height: 275px;
  }
  #quote-container {
    width: 275px;
  }
}

/* 'Mobile' View */
@media (max-width: 600px) {
  .App {
    margin: -2vw;
  }
  #main-container {
    flex-direction: column-reverse;
  }
  #picture-me {
    margin: auto;
    margin-top: 5vw;
    width: 90vw;
    height: 90vw;
    border-radius: 10vw;
  }
  #quote-container {
    width: 90vw;
    margin-left: 5.5vw;
  }
  #quote {
    font-size: 18px;
    padding: 4vw;
  }
}
